<template>
    <div class="qration_ing modal1">
        <div class="qration_ing_box modal_box">
            <div class="qration_ing_title modal_title">
                <h2>전체보기</h2>
                <div class="modal_close_btn" @click="$emit('closeCurationInfo')">
                    <img src="/media/images/icon_close_24.svg" alt="" />
                </div>
            </div>
            <div>
                <!-- 차량 -->
                <div class="type_title" v-if="curationInfo.carPurpose && curationInfo.carPurpose.length > 0">
                    <h3>이용목적</h3>
                    <div class="type_span">
                        <p v-for="(item, index) in this.curationInfo.carPurpose" :key="index">{{ item }}</p>
                    </div>
                </div>
                <div class="type_title" v-if="curationInfo.color">
                    <h3>주요 인테리어 색상</h3>
                    <div class="type_span">
                        <p>{{ this.curationInfo.color }}</p>
                    </div>
                </div>
                <div class="type_title" v-if="curationInfo.material">
                    <h3>주요 인테리어 소재</h3>
                    <div class="type_span">
                        <p v-for="(item, index) in this.curationInfo.material" :key="index">{{ item }}</p>
                    </div>
                </div>
                <div class="type_title" v-if="curationInfo.target && curationInfo.target.length > 0">
                    <h3>고객층</h3>
                    <div class="type_span">
                        <p v-for="(item, index) in this.curationInfo.target" :key="index">{{ item }}</p>
                    </div>
                </div>
                <div class="type_title" v-if="curationInfo.domesticRate">
                    <h3>선호 국가</h3>
                    <div class="type_span">
                        <p>국내 {{ this.curationInfo.domesticRate }}%</p>
                    </div>
                </div>
                <div class="type_title" v-if="curationInfo.genre && curationInfo.genre.length > 0">
                    <h3>장르</h3>
                    <div class="type_span">
                        <p v-for="(item, index) in this.curationInfo.genre" :key="index">{{ item }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'curationInfo',
  props: {
    curationInfo: Object
  }
});
</script>
