<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <CancelCheckModal v-if="cancelCheckStatus" :isRegistCard="isRegistCard" :spaceInfo="spaceInfo"
                          @closeCancel="cancelCheckStatus = false" @showCancel="cancelStatus = true" />
        <div class="space_pc">
            <CurationInfo :curationInfo="curationInfo" v-if="showCurationInfo" @closeCurationInfo="showCurationInfo = false" />
            <section class="main myspace w_940">
                <div class="main_box my_space3">
                    <div class="main_box_title">
                        <h1 tyle="margin-bottom:0px;">스페이스 등록</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white" />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content qration">
                            <div class="form_text">
                                <h3 tyle="margin-bottom:0px;">
                                    주로 방문하는 고객 단위는 어떤가요?
                                </h3>
                            </div>
                            <div class="pagnation">
                                <span></span>
                                <span style="width:45%"></span>
                            </div>
                            <div class="three_type fade-up">
                                <div>
                                    <ul class="acodian_tab" style="padding-left: 0px" @click="clickUnit()">
                                        <li style="
                          background: url('/media/img/image 1716.png') no-repeat
                            center 0;
                          background-size: cover;
                        ">
                                            <input type="checkbox" v-model="unit" value="1" hidden />
                                            <p>혼자</p>
                                            <div :class="{ on: selectItem[0] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white" />
                                                </svg>
                                            </div>
                                        </li>
                                        <li style="
                          background: url('/media/img/image 1714.png') no-repeat
                            center 0;
                          background-size: cover;
                        ">
                                            <input type="checkbox" v-model="unit" value="2" hidden />
                                            <p>친구</p>
                                            <div :class="{ on: selectItem[1] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white" />
                                                </svg>
                                            </div>
                                        </li>
                                        <li style="
                          background: url('/media/img/image 1717.png') no-repeat
                            center 0;
                          background-size: cover;
                        ">
                                            <input type="checkbox" v-model="unit" value="3" hidden />
                                            <p>연인</p>
                                            <div :class="{ on: selectItem[2] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white" />
                                                </svg>
                                            </div>
                                        </li>
                                        <li style="
                          background: url('/media/img/image 1718.png') no-repeat
                            center 0;
                          background-size: cover;
                        ">
                                            <input type="checkbox" v-model="unit" value="4" hidden />
                                            <p>가족</p>
                                            <div :class="{ on: selectItem[3] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white" />
                                                </svg>
                                            </div>
                                        </li>
                                        <li style="
                          background: url('/media/img/image 1719.png') no-repeat
                            center 0;
                          background-size: cover;
                        ">
                                            <input type="checkbox" v-model="unit" value="5" hidden />
                                            <p>동료</p>
                                            <div :class="{ on: selectItem[4] }">
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12.8582 1.98515C15.8783 -0.75307 20.5453 -0.662185 23.4549 2.28118C26.3631 5.22584 26.4634 9.91548 23.7583 12.9744L12.8556 24L1.95554 12.9744C-0.74955 9.91548 -0.64798 5.21805 2.25897 2.28118C5.17105 -0.65829 9.82911 -0.756965 12.8582 1.98515Z"
                                                        fill="white" />
                                                </svg>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form_bottom space_1">
                                <div class="form_bottom_box">
                                    <div>
                                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                                             @click="showCurationInfo = true">
                                            <path
                                                d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                                                fill="white" />
                                        </svg>
                                    </div>
                                    <div class="space_bottom_list" v-if="bottomList.length < 13">
                                        <div v-for="(item, index) in bottomList.slice().reverse()" :key="index"
                                             :style="'z-index: ' + (bottomList.length - index) + ';'">
                                            <img :src="item">
                                        </div>
                                    </div>
                                    <div class="space_bottom_list space_bottom_list_else" v-else>
                                        <div style="z-index: 12;">
                                            <img :src="bottomList[bottomList.length - 1]">
                                        </div>
                                        <div style="z-index: 11;">
                                            <img :src="bottomList[bottomList.length - 2]">
                                        </div>
                                        <div style="z-index: 10;">
                                            <img :src="bottomList[bottomList.length - 3]">
                                        </div>
                                        <div style="z-index: 9;">
                                            <img :src="bottomList[bottomList.length - 4]">
                                        </div>
                                        <div style="z-index: 8;">
                                            <img :src="bottomList[bottomList.length - 5]">
                                        </div>
                                        <div style="z-index: 7;">
                                            <img :src="bottomList[bottomList.length - 6]">
                                        </div>
                                        <div style="z-index: 6;">
                                            <img :src="bottomList[bottomList.length - 7]">
                                        </div>
                                        <div style="z-index: 5;">
                                            <img :src="bottomList[bottomList.length - 8]">
                                        </div>
                                        <div style="z-index: 4;">
                                            <img :src="bottomList[bottomList.length - 9]">
                                        </div>
                                        <div style="z-index: 3;">
                                            <img :src="bottomList[bottomList.length - 10]">
                                        </div>
                                        <div style="z-index: 2;">
                                            <img :src="bottomList[bottomList.length - 11]">
                                        </div>
                                        <div style="z-index: 1;">
                                            <img :src="bottomList[bottomList.length - 12]">
                                        </div>
                                        <div>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    <div>
                                        <button id="prev_btn" style="text-align: center" @click="clickPre()">
                                            이전
                                        </button>
                                        <button id="next_btn" style="text-align: center" v-if="unit.unitNo.length > 0"
                                                @click="updateCuration()">
                                            다음
                                        </button>
                                        <button v-else style="text-align: center; color: #5B5B5B; background: #4D1B24" disabled>다음</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo h_100">
            <CurationInfoM :curationInfo="curationInfo" v-if="showCurationInfoM"
                           @closeCurationInfo="showCurationInfoM = false" />
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()">
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close">
                        <img src="/media/images/icon_close_24.svg" @click="linkMySpace()">
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width:45%"></span>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 style="margin-bottom:0px;">주로 방문하는 고객 단위는 어떤가요?</h3>
                        </div>
                        <div class="types" @click="clickUnitMo()">
                            <ul style="padding-left:0px;">
                                <li style="
                      background: url('/media/img/image 1716.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                    <span :style="{ display: selectItem[0] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="unit" value="1" hidden />
                                    <p style="margin-bottom:0px;">혼자</p>
                                </li>
                                <li style="
                      background: url('/media/img/image 1714.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                    <span :style="{ display: selectItem[1] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="unit" value="2" hidden />
                                    <p style="margin-bottom:0px;">친구</p>
                                </li>
                                <li style="
                      background: url('/media/img/image 1717.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                    <span :style="{ display: selectItem[2] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="unit" value="3" hidden />
                                    <p style="margin-bottom:0px;">연인</p>
                                </li>
                                <li style="
                      background: url('/media/img/image 1718.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                    <span :style="{ display: selectItem[3] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="unit" value="4" hidden />
                                    <p style="margin-bottom:0px;">가족</p>
                                </li>
                                <li style="
                      background: url('/media/img/image 1719.png') no-repeat
                        center 0;
                      background-size: cover;
                    ">
                                    <span :style="{ display: selectItem[4] ? '' : 'none' }">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.001 1.52898C12.35 -0.58002 15.98 -0.51002 18.243 1.75698C20.505 4.02498 20.583 7.63698 18.479 9.99298L9.99901 18.485L1.52101 9.99298C-0.582994 7.63698 -0.503994 4.01898 1.75701 1.75698C4.02201 -0.50702 7.64501 -0.58302 10.001 1.52898Z"
                                                fill="white" />
                                        </svg>
                                    </span>
                                    <input type="checkbox" v-model="unit" value="5" hidden />
                                    <p style="margin-bottom:0px;">동료</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="types_bottom">
                        <svg @click="showInfo('unit')" width="14" height="8" viewBox="0 0 14 8" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.3025 2.92566L2.3525 7.87566L0.938504 6.46166L7.3025 0.0976563L13.6665 6.46166L12.2525 7.87566L7.3025 2.92566Z"
                                fill="white" />
                        </svg>
                        <div class="mo_space_bottom_list" v-if="bottomList.length < 8">
                            <div v-for="(item, index) in bottomList.slice().reverse()" :key="index"
                                 :style="'z-index: ' + (bottomList.length - index) + ';'">
                                <img :src="item">
                            </div>
                        </div>
                        <div class="mo_space_bottom_list mo_space_bottom_list_else" v-else>
                            <div style="z-index: 7;">
                                <img :src="bottomList[bottomList.length - 1]">
                            </div>
                            <div style="z-index: 6;">
                                <img :src="bottomList[bottomList.length - 2]">
                            </div>
                            <div style="z-index: 5;">
                                <img :src="bottomList[bottomList.length - 3]">
                            </div>
                            <div style="z-index: 4;">
                                <img :src="bottomList[bottomList.length - 4]">
                            </div>
                            <div style="z-index: 3;">
                                <img :src="bottomList[bottomList.length - 5]">
                            </div>
                            <div style="z-index: 2;">
                                <img :src="bottomList[bottomList.length - 6]">
                            </div>
                            <div style="z-index: 1;">
                                <img :src="bottomList[bottomList.length - 7]">
                            </div>
                            <div style="z-index: -1;">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="form_bottom">
                        <button style="text-align: center" v-if="unit.unitNo.length > 0" @click="updateCuration()">다음</button>
                        <button class="opacity3" style="text-align:center;" v-else>다음</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { getImg } from '@/assets/js/spaceBottom.js';
import CurationInfoM from "@/components/modal/Space/CurationInfoM.vue";
import CurationInfo from "@/components/modal/Space/CurationInfo.vue";
import CancelCheckModal from "@/components/modal/Space/CancelCheckModal.vue";
import CancelModal from "@/components/modal/Space/CancelModal.vue";
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";
import router from "@/router";

export default defineComponent({
  data () {
    return {
      isRegistCard: true,
      cancelStatus: false,
      cancelCheckStatus: false,
      showCurationInfo: false,
      showCurationInfoM: false,
      unit: {
        unitNo: []
      },
      curationList: [],
      curationInfo: {
        infoStatus: '',
        infoCategory: '',
        mood: '',
        target: []
      },
      tempList: [],
      allList: ['혼자', '친구', '연인', '가족', '동료'],
      selectItem: [],
      filterList: [],
      bottomList: []
    };
  },
  components: {
    CurationInfo,
    CancelModal,
    CancelCheckModal,
    CurationInfoM
  },
  methods: {
    checkRegistCard () {
      const userId = VueCookies.get("userId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/product/getMyCardInfo", { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            result != null ? this.isRegistCard = true : this.isRegistCard = false;
          }
        })
        .catch(e => { console.log(e); });
    },
    showInfo (index) {
      this.curationInfo.infoStatus = index;
      this.curationInfo.infoCategory = 'insert';
      this.showCurationInfoM = true;
    },
    linkMySpace () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/checkSubscribe", { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            if (res.data.result === 'S') {
              this.cancelCheckStatus = true;
            } else {
              router.push({ name: "Player" });
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateCuration () {
      var spaceId = localStorage.getItem("spaceId");
      var targetUnit = (this.unit.unitNo.sort((a, b) => { return a - b; })).toString();
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/updateCurationInfo", { spaceId, targetUnit }, { headers })
        .then((res) => {
          // console.log(res.data.result);
          // router.push({ name: "SpaceInsertStoreCountry" });
          axios
            .post("/api/profile/updateCurationTemp", { spaceId, targetUnit }, { headers })
            .then((res) => {
              // console.log(res.data.result);
              router.push({ name: "SpaceInsertStoreCountry" });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickPre () {
      // router.push({ name: "SpaceInsertStoreTarget" });
      history.back();
    },
    clickUnit () {
      $(document).off("click").on("click", ".acodian_tab > li", (e) => {
        // $(".acodian_tab > li").children("div").removeClass("on");
        $(e.currentTarget).children("div").toggleClass("on");
        // unit 값 넣고 빼기
        var unitList = this.unit.unitNo;
        var unit = parseInt(e.currentTarget.querySelector('input').value);

        var found = unitList.find(el => el === unit);
        if (found != null) { // 선택한 값이 있을때
          var filtered = unitList.filter((el) => el !== unit);
          this.unit.unitNo = filtered;
        } else { // 선택한 값이 없을때
          this.unit.unitNo.push(unit);
        }
      });
    },
    set () {
      $(".types ul li").children('span').hide();
    },
    clickUnitMo () {
      $(document).off("click").on("click", ".types ul li", (e) => {
        $(e.currentTarget).children('span').toggle();
        // unit 값 넣고 빼기
        var unitList = this.unit.unitNo;
        var unit = parseInt(e.currentTarget.querySelector('input').value);

        var found = unitList.find(el => el === unit);
        if (found != null) { // 선택한 값이 있을때
          var filtered = unitList.filter((el) => el !== unit);
          this.unit.unitNo = filtered;
        } else { // 선택한 값이 없을때
          this.unit.unitNo.push(unit);
        }
      });
    },
    openSelectBoxMo () {
      $('.types_bottom').find('svg').click(function () {
        $('.space_down_box1').css({ bottom: "0%", transition: "all 1s" });
      });
    },
    closeSelectBoxMo () {
      $(".space_down_box1").find('svg').click(function () {
        $(".space_down_box1").css({ bottom: "-100%", transition: "all 1s" });
      });
    },
    getCurationInfo () {
      var spaceId = localStorage.getItem("spaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          const stepList = [true, true, false, false, false, false, false];
          this.bottomList = getImg(res.data.result, stepList);
          // console.log(res.data.result);
          this.tempList = res.data.result.targetUnit;

          this.curationInfo.mood = res.data.result.mood;
          this.curationInfo.target = res.data.result.target;
          this.curationList = [this.curationInfo.mood, ...this.curationInfo.target];

          if (res.data.result.targetUnit != null) {
            this.selectTarget();
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectTarget () {
      var allList = this.allList;
      for (var i in allList) {
        this.selectItem[i] = this.tempList.indexOf(allList[i]) > -1;

        var filter = (allList.indexOf(this.tempList[i])) + 1;
        if (filter > 0) {
          this.filterList.push(filter);
        }
      }
      this.unit.unitNo = this.filterList;
    }
  },
  mounted () {
    this.set();
    this.getCurationInfo();
    this.checkRegistCard();
  }
});
</script>

<style scoped>
.select_curation {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.select_curation p {
  padding: 10px 12px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 8px;
  white-space: nowrap;
}

.types_bottom>svg {
  height: 15px;
}

h3 {
  margin: 0;
}

h1 {
  margin: 0;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
